import createStickyObserver from '@/scripts/helpers/sticky-observer';

interface AlumniResponse {
  results?: string;
  total?: number;
}

type Elements = {
  module: HTMLElement | null;
  intro: HTMLElement | null;
  formWrapper: HTMLElement | null;
  form: HTMLFormElement | null;
  sortButtons: HTMLButtonElement[];
  loadMore: HTMLButtonElement | null;
  resultsList: HTMLUListElement | null;
  showingCount: HTMLElement | null;
  totalCount: HTMLElement | null;
};

type Inputs = {
  name: HTMLInputElement | null;
  major: HTMLSelectElement | null;
  yearStart: HTMLSelectElement | null;
  yearEnd: HTMLSelectElement | null;
};

type FilterValues = {
  page: string | null;
  sort: string | null;
  order: string | null;
  search: string | null;
  major: string | null;
  yearStart: string | null;
  yearEnd: string | null;
};

type URLParams = {
  page?: string;
  sort?: string;
  order?: string;
  search?: string;
  major?: string;
  yearStart?: string;
  yearEnd?: string;
};

type InputKey = keyof Inputs;

type ParamToInputMap = {
  [K in keyof URLParams]: InputKey;
};

const paramToInputMap: ParamToInputMap = {
  search: 'name',
  major: 'major',
  yearStart: 'yearStart',
  yearEnd: 'yearEnd',
  page: 'name',    // These don't map to inputs but need to be included
  sort: 'name',    // for the ParamToInputMap type
  order: 'name'
};

interface AlumniSearch {
  postsPerPage: number;
  elements: Elements;
  inputs: Inputs;
  init(): void;
  setInputs(): void;
  bindEvents(): void;
  getValues(): FilterValues;
  getResults(page: number): void;
  handleSortClick(button: HTMLButtonElement): void;
  handleLoadMoreClick(): void;
  handleFormSubmit(e: SubmitEvent): void;
  updateURLParameters(values: FilterValues): void;
  setInitialStateFromURL(): void;
}

const alumniSearch: AlumniSearch = {
  postsPerPage: 48,

  elements: {
    module: document.querySelector('.module--people-search[data-search="alumni"]'),
    intro: document.querySelector('.module--people-search[data-search="alumni"] .module__intro'),
    formWrapper: document.querySelector('.module--people-search[data-search="alumni"] .module__form'),
    form: document.querySelector('.module--people-search[data-search="alumni"] form'),
    sortButtons: Array.from(document.querySelectorAll('.module--people-search[data-search="alumni"] button[data-action="sort"]')),
    loadMore: document.querySelector('.module--people-search[data-search="alumni"] button[data-action="load-more"]'),
    resultsList: document.querySelector('.module--people-search[data-search="alumni"] .module__content--list ul'),
    showingCount: document.querySelector('.module--people-search[data-search="alumni"] .module__content--header span[data-value="showing"]'),
    totalCount: document.querySelector('.module--people-search[data-search="alumni"] .module__content--header span[data-value="total"]'),
  },

  inputs: {
    name: null,
    major: null,
    yearStart: null,
    yearEnd: null,
  },

  init() {
    if (!this.elements.module) {
      return;
    }

    this.setInputs();
    this.setInitialStateFromURL();
    this.bindEvents();
    this.getResults(parseInt(this.elements.module.dataset.page || '1', 10));
  },

  setInputs() {
    if(!this.elements.form) {
      return;
    }

    this.inputs.name = this.elements.form.querySelector('input[name="people-name"]');
    this.inputs.major = this.elements.form.querySelector('select[name="people-major"]');
    this.inputs.yearStart = this.elements.form.querySelector('select[name="people-year-start"]');
    this.inputs.yearEnd = this.elements.form.querySelector('select[name="people-year-end"]');
  },

  bindEvents() {
    this.elements.form?.addEventListener('submit', (e) => this.handleFormSubmit(e));
    this.elements.sortButtons.forEach((button) => {
      button.addEventListener('click', () => this.handleSortClick(button));
    });
    this.elements.loadMore?.addEventListener('click', () => this.handleLoadMoreClick());

    window.addEventListener('popstate', () => {
      this.setInitialStateFromURL();
      if (this.elements.module) {
        this.getResults(parseInt(this.elements.module.dataset.page || '1', 10));
      }
    });

    window.addEventListener('load', () => {
      if(this.elements.intro) {
        createStickyObserver(this.elements.intro);
      }

      if(this.elements.formWrapper) {
        createStickyObserver(this.elements.formWrapper);
      }
    });
  },

  getValues(): FilterValues {
    return {
      page: this.elements.module?.dataset.page || null,
      sort: this.elements.module?.dataset.sort || null,
      order: this.elements.module?.dataset.order || null,
      search: this.inputs.name?.value || null,
      major: this.inputs.major?.value || null,
      yearStart: this.inputs.yearStart?.value || null,
      yearEnd: this.inputs.yearEnd?.value || null,
    };
  },

  getResults(page: number) {
    const values = this.getValues();
    const showingCount = page * this.postsPerPage;

    this.elements.module?.classList.add('loading');

    this.updateURLParameters({ ...values, page: page.toString() });

    const backendData = {
      action: 'action_get_alumni',
      selected: {
        ...values,
        page,
        name: values.search,
        major: values.major,
        'year-start': values.yearStart,
        'year-end': values.yearEnd,
        types: this.elements.module?.dataset.types || null,
      },
    };

    window.jQuery.ajax({
      method: 'POST',
      url: window.curtis.ajaxurl,
      data: backendData,
    })
    .done((success: string) => {
      let data: AlumniResponse;
      try {
        data = JSON.parse(success);
      } catch (e) {
        console.error('Failed to parse JSON response:', e);
        return;
      }

      if(this.elements.resultsList && data.results && page > 1) {
        this.elements.resultsList.innerHTML = `${this.elements.resultsList.innerHTML}${data.results}`;
      } else if(this.elements.resultsList && data.results && (page === 1 || page === -1)) {
        this.elements.resultsList.innerHTML = data.results;
      } else if(this.elements.resultsList) {
        this.elements.resultsList.innerHTML = '<li class="not-found">No results found</li>';
      }

      if(data.total !== undefined) {
        /* update the page number */
        this.elements.module?.setAttribute('data-page', `${page}`);

        /* update showing count */
        if(this.elements.showingCount && page !== -1) {
          this.elements.showingCount.textContent = `${showingCount > data.total ? data.total : showingCount}`;
        } else if (this.elements.showingCount && page === -1) {
          this.elements.showingCount.textContent = `${data.total}`;
        }

        /* update total matches */
        if(this.elements.totalCount) {
          this.elements.totalCount.textContent = `${data.total}`;
        }

        /* hide the load button if we have all results */
        if(page === -1 || showingCount >= data.total) {
          this.elements.loadMore?.classList.add('hidden');
        } else {
          this.elements.loadMore?.classList.remove('hidden');
        }
      }
    })
    .fail((_jqXHR: JQuery.jqXHR, _textStatus: string, errorThrown: string) => {
      console.error('AJAX request failed:', errorThrown);
    })
    .always(() => this.elements.module?.classList.remove('loading'));
  },

  handleSortClick(button: HTMLButtonElement) {
    const newSortType = button.dataset.type || '';
    const newSortOrder = button.dataset.order || 'asc';
    const currentSortType = this.elements.module?.dataset.sort || '';
    const currentSortOrder = this.elements.module?.dataset.order || 'asc';
    const page = this.elements.module?.dataset.page || '1';

    let finalSortOrder: 'asc' | 'desc';

    if (newSortType === currentSortType) {
      finalSortOrder = currentSortOrder === 'asc' ? 'desc' : 'asc';
      button.setAttribute('data-order', finalSortOrder);
    } else {
      finalSortOrder = newSortOrder as 'asc' | 'desc';
    }

    this.elements.module?.setAttribute('data-sort', newSortType);
    this.elements.module?.setAttribute('data-order', finalSortOrder);

    if (page !== '-1') {
      this.elements.module?.setAttribute('data-page', '1');
    }

    this.getResults(parseInt(page, 10));
  },

  handleLoadMoreClick() {
    const newPage = parseInt(this.elements.module?.dataset.page || '1') + 1;
    this.getResults(newPage);
  },

  handleFormSubmit(e: SubmitEvent) {
    e.preventDefault();

    const page = this.elements.module?.dataset.page || '1';
    if(page !== '-1') {
      this.elements.module?.setAttribute('data-page', '1');
    }

    this.getResults(parseInt(page, 10));
  },

  updateURLParameters(values: FilterValues) {
    const searchParams = new URLSearchParams(window.location.search);
    Object.entries(values).forEach(([ key, value ]) => {
        // Only set parameters if they have a meaningful value
        if (value !== null && value !== '' && value !== 'all' && value !== 'asc') {
            searchParams.set(key, value);
        } else {
            searchParams.delete(key);
        }
    });

    const newURL = `${window.location.pathname}${searchParams.toString() ? '?' + searchParams.toString() : ''}`;
    window.history.pushState(null, '', newURL);
  },

  setInitialStateFromURL() {
    const searchParams = new URLSearchParams(window.location.search);
    const params: Partial<URLParams> = {};

    searchParams.forEach((value, key) => {
      if (key in paramToInputMap) {
        params[key as keyof URLParams] = value;
      }
    });

    Object.entries(params).forEach(([ key, value ]) => {
      const inputKey = paramToInputMap[key as keyof URLParams];
      if (inputKey && value) {
        const input = this.inputs[inputKey];
        if (input && (input instanceof HTMLSelectElement || input instanceof HTMLInputElement)) {
          input.value = value;
        }
      }
    });
  }
};

export default alumniSearch;
